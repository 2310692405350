document.addEventListener("DOMContentLoaded", function() {
  const tabs = document.querySelectorAll('.service-tabs__tab');
  const tabContents = document.querySelectorAll('.service-contents__content');

  function updateTab(target) {
      tabs.forEach(t => t.classList.remove('active'));
      tabContents.forEach(content => content.classList.remove('active'));

      const activeTab = document.querySelector(`.service-tabs__tab[data-tab="${target}"]`);
      if (activeTab) {
          activeTab.classList.add('active');
          document.getElementById(target).classList.add('active');
      }

      const cleanHash = `#tab:resources:${target}`;
      window.history.replaceState(null, null, cleanHash);
  }

  function initializeTabs() {
      const hash = window.location.hash.substring(1); 
      if (hash) {
          const tab = hash.replace('tab:resources:', ''); 
          updateTab(tab);
      } else {
          // Default to the first tab if no hash is found
          updateTab('incorperation');
      }
  }
  initializeTabs();
  tabs.forEach(tab => {
      tab.addEventListener('click', () => {
          const target = tab.getAttribute('data-tab');
          updateTab(target);
      });
  });
});